<!--
 * @Author: ch3nh2
 * @Date: 2023-08-26 12:24:35
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-08-31 11:22:43
 * @FilePath: \beilunchanye_fe_20211208\src\views\xyds.vue
 * @Description: 馨雅大厦
-->

<template>
	<div class="home xyds">
		<!-- 导航菜单 -->
		<div class="xyds-btn-wrap">
			<div
				v-for="(item, index) in nav_btns"
				:key="index"
				:class="{
					'xyds-btn-item': true,
					'xyds-btn-item-active': nav_active == index,
				}"
				@click="onNavItem({ item, index })"
			>
				{{ item }}
			</div>
		</div>
		<!-- 周围边框 -->
		<div class="xyds-border-wrap">
			<transition name="el-fade-in">
				<div class="xyds-border" v-show="border" />
			</transition>
			<transition name="el-fade-in">
				<div class="xyds-border" v-show="border" />
			</transition>
			<transition name="el-fade-in">
				<div class="xyds-border" v-show="border" />
			</transition>
			<transition name="el-fade-in">
				<div class="xyds-border" v-show="border" />
			</transition>
		</div>
		<!-- 园区总览 -->
		<Overview v-if="nav_active == 0" />
		<!-- 园区招商 -->
		<Merchants v-if="nav_active == 1" />
		<!-- 园区运营 -->
		<Operate v-if="nav_active == 2" />
	</div>
</template>

<script>
	import Overview from '@/components/xyds/Overview.vue';
	import Merchants from '@/components/xyds/Merchants.vue';
	import Operate from '@/components/xyds/Operate.vue';
	export default {
		data() {
			return {
				border: false,
				nav_btns: ['园区总览', '园区招商', '园区运营'],
				nav_active: 0,
			};
		},
		watch: {},
		computed: {},
		components: {
			Overview,
			Merchants,
			Operate,
		},
		methods: {
			onNavItem({ index }) {
				this.nav_active = index;
			},
		},
		mounted() {
			this.border = true;
		},
	};
</script>

<style lang="less">
	.xyds {
		.xyds-btn-wrap {
			z-index: 9999;
			position: absolute;
			top: 40px;
			left: 80px;
			width: 440px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.xyds-btn-item {
				cursor: pointer;
				user-select: none;
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #fefefe;
				transition: all 0.2s;
				&:active {
					opacity: 0.5;
				}
			}
			.xyds-btn-item-active {
				position: relative;
				font-size: 24px;
				font-family: PingFang SC;
				font-weight: 600;
				color: #fefefe;
				transition: all 0.2s;
				text-shadow: 0px 0px 10px #ee9415;
				&::before {
					position: absolute;
					content: '';
					z-index: -1;
					top: 11px;
					left: -25px;
					width: 145px;
					height: 32px;
					display: inline-block;
					background-size: 100%;
					background-repeat: no-repeat;
					background-image: url(../assets/images/xyds/nav_btn_active.png);
				}
			}
		}
		.xyds-border-wrap {
			position: relative;
			height: 100%;
			pointer-events: none;
			.xyds-border {
				z-index: 9999;
				position: absolute;
				background-size: 100%;
				background-repeat: no-repeat;
				&:nth-child(1),
				&:nth-child(2) {
					top: 18px;
					width: 18px;
					height: 228px;
				}
				&:nth-child(1) {
					left: 0;
					background-image: url(../assets/images/xyds/border_top_1.png);
				}
				&:nth-child(2) {
					right: 0;
					background-image: url(../assets/images/xyds/border_top_2.png);
				}
				&:nth-child(3),
				&:nth-child(4) {
					bottom: 0;
					width: 141px;
					height: 216px;
				}
				&:nth-child(3) {
					left: 0;
					background-image: url(../assets/images/xyds/border_bottom_1.png);
				}
				&:nth-child(4) {
					right: 0;
					background-image: url(../assets/images/xyds/border_bottom_2.png);
				}
			}
		}
		.xyds-components {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			width: 100%;
			height: 100%;
			.xyds-wrap {
				z-index: 10;
				position: absolute;
				width: 420px;
				height: 100%;
				.xyds-item {
					width: 420px;
					position: absolute;
					.xyds-item-title-img {
						position: relative;
						z-index: 1;
						width: 100%;
						height: 44px;
						background-size: 100%;
						background-repeat: no-repeat;
						background-image: url(../assets/images/xyds/item_title.png);
						.xyds-item-title {
							position: absolute;
							top: -8px;
							left: 45px;
							font-size: 24px;
							font-family: PingFang SC;
							font-weight: 500;
							color: #ffffff;
							text-shadow: 0px 0px 10px #21a2fc;
						}
					}
					.xyds-item-content {
						width: 100%;
						top: -16px;
						overflow-y: scroll;
						position: relative;
						&::-webkit-scrollbar {
							width: 8px;
						}
						&::-webkit-scrollbar-thumb {
							margin-top: 10px;
							border-radius: 10px;
							background-color: #0b3f79;
						}
						&::-webkit-scrollbar-track {
							opacity: 0;
						}
					}
				}
			}
			.xyds-left-wrap {
				left: 0;
				padding-left: 30px;
				background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent);
				.xyds-item > .xyds-item-content {
					background-image: linear-gradient(to right, rgba(11, 63, 121, 0.4), transparent);
				}
			}

			.xyds-right-wrap {
				right: 0;
				padding-right: 30px;
				background-image: linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent);
				.xyds-item > .xyds-item-content {
					background-image: linear-gradient(to left, rgba(11, 63, 121, 0.4), transparent);
				}
			}
		}
	}
</style>
