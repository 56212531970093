<!--
 * @Author: ch3nh2
 * @Date: 2023-08-28 17:40:37
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-08-31 17:23:39
 * @FilePath: \beilunchanye_fe_20211208\src\components\xyds\Merchants.vue
 * @Description: 馨雅大厦 - 园区招商
-->

<template>
	<div class="xyds-components xyds-merchants">
		<div v-show="$store.state.animateDelay && $store.state.mapType" class="xyds-wrap xyds-left-wrap" :class="{ animate__animated: true, animate__fadeInLeftBig: $store.state.showDataValue, animate__fadeOutLeftBig: !$store.state.showDataValue }">
			<!-- 园区出租情况 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">园区出租情况</div>
				</div>
				<div class="xyds-item-content">
					<div class="xyds-item-top-wrap">
						<div class="xyds-item-top-item" v-for="(item, index) in leaseholdTop" :key="index">
							<div class="xyds-item-top-item-data">
								<CountUp :delay="1000" :endVal="item.count" :options="{ decimalPlaces: 2 }" />
								<b>(%)</b>
							</div>
							<p>{{ item.name }}</p>
						</div>
					</div>
					<div class="xyds-item-bottom-wrap">
						<div class="xyds-item-bottom-item" v-for="(item, index) in leaseholdBottom" :key="index">
							<div class="xyds-item-bottom-item-data">
								<div class="xyds-item-bottom-item-data-name">可租赁面积</div>
								<div class="xyds-item-bottom-item-data-number">
									<CountUp :delay="1000" :endVal="item.n1" :options="{ decimalPlaces: 2 }" />
									<b>㎡</b>
								</div>
							</div>
							<div class="xyds-item-bottom-item-data">
								<div class="xyds-item-bottom-item-data-name">已租赁面积</div>
								<div class="xyds-item-bottom-item-data-number">
									<CountUp :delay="1000" :endVal="item.n2" :options="{ decimalPlaces: 2 }" />
									<b>㎡</b>
								</div>
							</div>
							<div class="xyds-item-bottom-item-data">
								<div class="xyds-item-bottom-item-data-name">未租赁面积</div>
								<div class="xyds-item-bottom-item-data-number">
									<CountUp :delay="1000" :endVal="item.n3" :options="{ decimalPlaces: 2 }" />
									<b>㎡</b>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 空置房源 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">空置房源</div>
				</div>
				<div class="xyds-item-content">
					<div class="xyds-item-content-wrap">
						<div class="xyds-item-content-title-wrap">
							<div class="xyds-item-content-title">序号</div>
							<div class="xyds-item-content-title">房号</div>
							<div class="xyds-item-content-title">面积<span>(㎡)</span></div>
						</div>
						<div class="xyds-item-content-item" v-for="(item, index) in realEstate" :key="index">
							<div class="xyds-item-content-item-left">{{ item.name }}</div>
							<div class="xyds-item-content-item-right">
								<div class="xyds-item-content-item-right-item" v-for="(jtem, jndex) in item.child" :key="jndex">
									<span>{{ jtem.n1 }}</span>
									<CountUp :delay="1000" :endVal="jtem.n2" :options="{ decimalPlaces: 2 }" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-show="$store.state.animateDelay && $store.state.mapType" class="xyds-wrap xyds-right-wrap" :class="{ animate__animated: true, animate__fadeInRightBig: $store.state.showDataValue, animate__fadeOutRightBig: !$store.state.showDataValue }">
			<!-- 入驻商业 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">入驻商业</div>
				</div>
				<div class="xyds-item-content">
					<div class="xyds-item-content-wrap">
						<div class="xyds-item-content-item" v-for="(item, index) in business" :key="index">
							<div class="xyds-item-content-item-logo" :style="{ border: index % 2 ? '1px solid #46ffff' : '1px solid #1a96ff' }">
								<img :src="item.logo" :alt="item.name" />
							</div>
							<div class="xyds-item-content-item-name">{{ item.name }}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 入驻优势 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">入驻优势</div>
				</div>
				<div class="xyds-item-content">
					<transition name="el-zoom-in-top">
						<div class="xyds-item-text">馨雅大厦位于西四环主路，大厦周边路网交通便利，公交400、114路、664路、121路、983路等十余条公交直达，大厦东临西四环、西邻海淀招商大厦、北邻四季青镇政府、南邻郦城二区，交通便捷、配套齐全、可视性极高。</div>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CountUp from 'vue-countup-v2';
	export default {
		data() {
			return {
				leaseholdTop: [
					{
						name: '产业空置率',
						count: 1.34,
					},
					{
						name: '商业空置率',
						count: 94.15,
					},
				],
				leaseholdBottom: [
					{
						n1: 14028.58,
						n2: 13840.17,
						n3: 188.41,
					},
					{
						n1: 2019.83,
						n2: 118.21,
						n3: 1901.62,
					},
				],
				realEstate: [
					{
						name: '1',
						child: [
							{
								n1: '1-1',
								n2: 548.01,
							},
							{
								n1: '1-2',
								n2: 548.01,
							},
						],
					},
					{
						name: '2',
						child: [
							{
								n1: '1-3',
								n2: 205.65,
							},
						],
					},
					{
						name: '3',
						child: [
							{
								n1: '1-4',
								n2: 286.38,
							},
						],
					},
					{
						name: '4',
						child: [
							{
								n1: '1-5',
								n2: 101.24,
							},
						],
					},
					{
						name: '5',
						child: [
							{
								n1: '1-6',
								n2: 78.81,
							},
						],
					},
					{
						name: '6',
						child: [
							{
								n1: '1-7',
								n2: 78.81,
							},
						],
					},
					{
						name: '7',
						child: [
							{
								n1: '1-9A',
								n2: 81.04,
							},
						],
					},
					{
						name: '8',
						child: [
							{
								n1: '1-9B',
								n2: 74.17,
							},
						],
					},
					{
						name: '9',
						child: [
							{
								n1: '1-10',
								n2: 447.51,
							},
						],
					},
				],
				business: [
					{
						logo: require('@/assets/images/xyds/xbk.jpg'),
						name: '星巴克咖啡',
					},
				],
			};
		},
		watch: {},
		computed: {},
		components: {
			CountUp,
		},
		methods: {},
		mounted() {},
	};
</script>

<style lang="less">
	.xyds-merchants {
		.xyds-left-wrap {
			.xyds-item {
				&:nth-child(1) {
					top: 105px;
					.xyds-item-content {
						height: 330px;
						.xyds-item-top-wrap {
							padding: 36px 20px;
							display: flex;
							align-items: center;
							justify-content: space-between;
							.xyds-item-top-item {
								width: 166px;
								height: 140px;
								display: flex;
								align-items: center;
								justify-content: center;
								flex-direction: column;
								background-size: 100%;
								background-repeat: no-repeat;

								.xyds-item-top-item-data {
									margin-bottom: 4px;
									span {
										font-size: 36px;
										font-weight: bold;
										color: #fefefe;
										font-family: 'bigdsfont';
									}
								}

								p {
									font-size: 16px;
									font-family: PingFang SC;
									font-weight: 400;
									color: #ffffff;
								}

								&:nth-child(1) {
									background-image: url(../../assets/images/xyds/merchants_item_01_01.png);
								}
								&:nth-child(2) {
									background-image: url(../../assets/images/xyds/merchants_item_01_02.png);
								}
							}
						}
						.xyds-item-bottom-wrap {
							display: flex;
							align-items: center;
							justify-content: space-between;
							padding-top: 6px;
							.xyds-item-bottom-item {
								width: 202px;
								height: 112px;
								background-size: 100%;
								background-repeat: no-repeat;
								display: flex;
								justify-content: center;
								flex-direction: column;
								.xyds-item-bottom-item-data {
									display: flex;
									align-items: center;
									margin-bottom: 4px;
									&:nth-child(1) {
										margin: 10px 0;
										.xyds-item-bottom-item-data-name {
											font-size: 14px;
										}
										.xyds-item-bottom-item-data-number {
											span {
												color: #1a99ff;
											}
										}
									}
									.xyds-item-bottom-item-data-name {
										width: 35%;
										margin: 0 8px;
										font-size: 13px;
										font-family: PingFang SC;
										font-weight: 500;
										color: #ffffff;
										opacity: 0.8;
									}
									.xyds-item-bottom-item-data-number {
										span {
											font-size: 24px;
											font-family: 'bigdsfont';
											font-weight: bold;
											color: #ffffff;
											margin-right: 2px;
										}
										b {
											font-size: 14px;
										}
									}
								}
								&:nth-child(1) {
									background-image: url(../../assets/images/xyds/merchants_item_01_03.png);
								}
								&:nth-child(2) {
									background-image: url(../../assets/images/xyds/merchants_item_01_04.png);
								}
							}
						}
					}
				}
				&:nth-child(2) {
					top: 495px;
					.xyds-item-content {
						height: 532px;
						.xyds-item-content-wrap {
							.xyds-item-content-title-wrap {
								display: flex;
								align-items: center;
								justify-content: space-between;
								border-bottom: solid transparent 1px;
								border-image: linear-gradient(to right, transparent, #1a99ff, rgb(88, 251, 251), #1a99ff, transparent) 1 0;

								.xyds-item-content-title {
									font-size: 18px;
									font-weight: bold;
									color: #ffffff;
									font-family: PingFang SC;
									padding: 22px 40px;
									span {
										font-size: 14px;
									}
								}
							}
							.xyds-item-content-item {
								display: flex;
								align-items: center;
								border-bottom: solid transparent 1px;
								border-image: linear-gradient(to right, transparent, #1a99ff, rgb(88, 251, 251), #1a99ff, transparent) 1 0;
								.xyds-item-content-item-left {
									width: 28%;
									padding: 8px 0;
									display: flex;
									align-items: center;
									justify-content: center;
									font-size: 18px;
									font-weight: 400;
									color: #ffffff;
									font-family: PingFang SC;
								}
								.xyds-item-content-item-right {
									width: 75%;
									.xyds-item-content-item-right-item {
										display: flex;
										align-items: center;
										justify-content: center;
										padding: 8px 0;
										border-bottom: solid transparent 1px;
										border-image: linear-gradient(to right, transparent, #1a99ff, rgb(88, 251, 251), #1a99ff, transparent) 1 0;
										&:last-child {
											border-bottom: none;
										}
										span {
											text-align: center;
											&:nth-child(1) {
												width: 55%;
											}
											&:nth-child(2) {
												width: 45%;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.xyds-right-wrap {
			.xyds-item {
				&:nth-child(1) {
					top: 105px;
					.xyds-item-content {
						height: 410px;
						.xyds-item-content-wrap {
							padding: 20px 0;
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							.xyds-item-content-item {
								width: 25%;
								margin: 12.5px 0;
								display: flex;
								align-items: center;
								justify-content: center;
								flex-direction: column;
								.xyds-item-content-item-logo {
									width: 70px;
									height: 70px;
									border-radius: 20px;
									margin-bottom: 12px;
									display: flex;
									align-items: center;
									justify-content: center;
									img {
										max-width: 55px;
										max-height: 55px;
										border-radius: 10px;
									}
								}
								.xyds-item-content-item-name {
									color: #ffffff;
									font-size: 18px;
									font-weight: 500;
									font-family: PingFang SC;
								}
							}
						}
					}
				}
				&:nth-child(2) {
					top: 580px;
					.xyds-item-content {
						height: 445px;
						.xyds-item-text {
							padding: 15px;
							font-size: 20px;
							line-height: 40px;
							font-weight: 400;
							color: #ffffff;
							font-family: PingFang SC;
						}
					}
				}
			}
		}
	}
</style>
