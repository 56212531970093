<!--
 * @Author: ch3nh2
 * @Date: 2023-08-28 17:40:52
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-09-01 01:43:49
 * @FilePath: \beilunchanye_fe_20211208\src\components\xyds\Operate.vue
 * @Description: 馨雅大厦 - 园区运营
-->

<template>
	<div class="xyds-components xyds-operate">
		<div v-show="$store.state.animateDelay && $store.state.mapType" class="xyds-wrap xyds-left-wrap" :class="{ animate__animated: true, animate__fadeInLeftBig: $store.state.showDataValue, animate__fadeOutLeftBig: !$store.state.showDataValue }">
			<!-- 食堂用餐人数 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">食堂用餐人数</div>
				</div>
				<div class="xyds-item-content">
					<div class="xyds-item-content-item" v-for="(item, index) in dining" :key="index">
						<CountUp :delay="1000" :endVal="item" />
					</div>
				</div>
			</div>
			<!-- 能耗使用情况 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">能耗使用情况</div>
				</div>
				<div class="xyds-item-content">
					<div id="chart1" :style="{ width: '100%', height: '280px' }"></div>
				</div>
			</div>
			<!-- 充电桩使用情况 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">充电桩使用情况</div>
				</div>
				<div class="xyds-item-content">
					<div id="chart2" :style="{ width: '100%', height: '310px' }"></div>
				</div>
			</div>
			<!-- 会议室预定情况 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">会议室预定情况</div>
				</div>
				<div class="xyds-item-content">
					<div class="xyds-item-content-title">
						<span>名称</span>
						<span>地点</span>
						<span>可容纳人数</span>
						<span>状态</span>
					</div>
					<div class="xyds-item-content-item-wrap">
						<div class="xyds-item-content-item">
							<span>会议室</span>
							<span>306</span>
							<span>150+</span>
							<span>已预订</span>
						</div>
						<div class="xyds-item-content-item">
							<span>会议室</span>
							<span>303</span>
							<span>150+</span>
							<span>已预订</span>
						</div>
						<div class="xyds-item-content-item">
							<span>会议室</span>
							<span>316</span>
							<span>150+</span>
							<span>已预订</span>
						</div>
						<div class="xyds-item-content-item">
							<span>会议室</span>
							<span>437</span>
							<span>150+</span>
							<span>已预订</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-show="$store.state.animateDelay && $store.state.mapType" class="xyds-wrap xyds-right-wrap" :class="{ animate__animated: true, animate__fadeInRightBig: $store.state.showDataValue, animate__fadeOutRightBig: !$store.state.showDataValue }">
			<!-- 园区进出人员情况 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">园区进出人员情况</div>
				</div>
				<div class="xyds-item-content">
					<div id="CarAccess" :style="{ width: '100%', height: '280px' }"></div>
				</div>
			</div>
			<!-- 园区进出车辆情况 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">园区进出车辆情况</div>
				</div>
				<div class="xyds-item-content">
					<div id="chart4" :style="{ width: '100%', height: '310px' }"></div>
				</div>
			</div>
			<!-- 周边实时违规事项 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">周边实时违规事项</div>
				</div>
				<div class="xyds-item-content">
					<div class="xyds-item-content-title">
						<span>时间</span>
						<span>地点</span>
						<span>类型</span>
						<span>状态</span>
					</div>
					<div class="xyds-item-content-item-wrap">
						<div class="xyds-item-content-item">
							<span>2023-08-27 23:43:17</span>
							<span>B2南侧</span>
							<span>未知</span>
							<span>已处理</span>
						</div>
						<div class="xyds-item-content-item">
							<span>2023-08-28 03:33:34</span>
							<span>一层西侧</span>
							<span>未知</span>
							<span>已处理</span>
						</div>
						<div class="xyds-item-content-item">
							<span>2023-08-29 20:53:23</span>
							<span>一层北侧</span>
							<span>未知</span>
							<span>已处理</span>
						</div>
						<div class="xyds-item-content-item">
							<span>2023-08-30 13:23:47</span>
							<span>一层北侧</span>
							<span>未知</span>
							<span>已处理</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CountUp from 'vue-countup-v2';
	export default {
		data() {
			return {
				dining: [1, 3, 5, 7, 7],
			};
		},
		watch: {},
		computed: {},
		components: {
			CountUp,
		},
		methods: {
			drawChart1() {
				const chart = this.$echarts.init(document.getElementById('chart1'));
				chart.setOption({
					xAxis: [
						{
							type: 'category',
							data: ['8/24', '8/25', '8/26', '8/27', '8/28', '8/29', '8/30', '8/31'],
						},
					],
					yAxis: [
						{
							type: 'value',
							name: '水',
							min: 0,
							max: 250,
							interval: 50,
							nameTextStyle: {
								color: '#ffffff',
							},
							splitLine: {
								//网格线
								lineStyle: {
									color: '#424555',
									type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
									// width: 4,
								},
								show: true, //隐藏或显示
							},
						},
						{
							type: 'value',
							name: '电',
							min: 0,
							max: 500,
							interval: 100,
							nameTextStyle: {
								color: '#ffffff',
							},
							splitLine: {
								//网格线
								lineStyle: {
									color: '#424555',
									type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
									// width: 4,
								},
								show: true, //隐藏或显示
							},
						},
					],
					series: [
						{
							type: 'bar',
							data: [97.75, 103.1, 84.72, 53.49, 69.26, 101.81, 78.34, 80.25],
							itemStyle: {
								color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{ offset: 0, color: '#83bff6' },
									{ offset: 0.5, color: '#188df0' },
									{ offset: 1, color: '#188df0' },
								]),
							},
						},
						{
							type: 'line',
							yAxisIndex: 1,
							data: [451.74, 463.75, 373.68, 355.04, 442.61, 484.37, 457.82, 478.19],
							itemStyle: {
								color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{ offset: 0, color: '#E3352E' },
									{ offset: 0.5, color: '#E3352E' },
									{ offset: 1, color: '#D9C341' },
									{ offset: 1, color: '#D9C341' },
								]),
							},
						},
					],
				});
			},
			drawChart2() {
				const chart = this.$echarts.init(document.getElementById('chart2'));
				chart.setOption({
					xAxis: [
						{
							type: 'category',
							data: ['8/23', '8/24', '8/25', '8/26', '8/27', '8/28', '8/29', '8/30', '8/31'],
						},
					],
					yAxis: [
						{
							type: 'value',
							splitLine: {
								//网格线
								lineStyle: {
									color: '#424555',
									type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
									// width: 4,
								},
								show: true, //隐藏或显示
							},
						},
					],
					series: [
						{
							name: '充电次数',
							type: 'bar',
							data: [2, 1, 11, 2, 4, 9, 4, 8, 10],
							itemStyle: {
								color: '#1A96FF',
							},
						},
						{
							name: '充电电量',
							type: 'bar',
							data: [0.79, 0.32, 39.95, 1.38, 3.04, 47.95, 2.84, 45.28, 51.32],
							itemStyle: {
								color: '#1FECFF',
							},
						},
					],
				});
			},
			drawChart3() {
				let myChart = this.$echarts.init(document.getElementById('CarAccess'));
				myChart.setOption({
					tooltip: {
						trigger: 'axis',
					},
					legend: {
						data: ['人数'],
						top: '4%',
						left: '63.5%',
						itemHeight: 8,
						itemWidth: 8,
						// show: true,
						textStyle: {
							color: '#fff',
							fontSize: '15',
						},
					},
					xAxis: {
						type: 'category',
						data: ['8/23', '8/24', '8/25', '8/26', '8/27', '8/28', '8/29', '8/30', '8/31'],
						lineStyle: {
							color: 'rgba(150,215,215,0.8)',
						},
						// 底部线颜色
						axisLine: {
							type: 'dashed',
							color: '#fff',
						},
					},
					yAxis: {
						name: '单位：人/次',
						//   max: 500,
						color: '#fff',
						type: 'value',
						splitLine: {
							//网格线
							lineStyle: {
								color: '#424555',
								type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
								// width: 4,
							},
							show: true, //隐藏或显示
						},
					},
					series: [
						{
							name: '人数',
							data: [1273, 1302, 1268, 403, 318, 1324, 1234, 1265, 1287],
							type: 'line',
							smooth: false,
							symbol: 'rectangle',
							symbolSize: 0,
							itemStyle: {
								normal: {
									// color:'red',//拐点颜色
									borderColor: 'rgba(22,141,222,1)', //拐点边框颜色
									borderWidth: 1, //拐点边框大小
								},
							},
							areaStyle: {
								//这是阴影部分
								normal: {
									color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{
											offset: 0,
											color: 'rgba(22,141,222,0.8)',
										},
										{
											offset: 0.4,
											color: 'rgba(22,141,222,0.3)',
										},
										{
											offset: 1,
											color: 'rgba(22,141,222,0.00)',
										},
									]),
								},
							},
						},
					],
				});
			},
			drawChart4() {
				const chart = this.$echarts.init(document.getElementById('chart4'));
				chart.setOption({
					xAxis: [
						{
							type: 'category',
							data: ['8/23', '8/24', '8/25', '8/26', '8/27', '8/28', '8/29', '8/30', '8/31'],
						},
					],
					yAxis: [
						{
							type: 'value',
							splitLine: {
								//网格线
								lineStyle: {
									color: '#424555',
									type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
									// width: 4,
								},
								show: true, //隐藏或显示
							},
						},
					],
					series: [
						{
							name: '进场',
							type: 'bar',
							data: [293, 243, 219, 74, 72, 244, 280, 235, 252],
							markPoint: {
								data: [
									{ type: 'max', name: 'Max' },
									{ type: 'min', name: 'Min' },
								],
							},
							itemStyle: {
								color: '#1A96FF',
							},
						},
						{
							name: '出场',
							type: 'bar',
							data: [309, 309, 304, 77, 66, 291, 315, 221, 243],
							markPoint: {
								data: [
									{ type: 'max', name: 'Max' },
									{ type: 'min', name: 'Min' },
								],
							},
							itemStyle: {
								color: '#1FECFF',
							},
						},
					],
				});
			},
		},
		mounted() {
			this.drawChart1();
			this.drawChart2();
			this.drawChart3();
			this.drawChart4();
		},
	};
</script>

<style lang="less">
	.xyds-operate {
		.xyds-left-wrap {
			.xyds-item {
				&:nth-child(1) {
					top: 105px;
					.xyds-item-content {
						height: 105px;
						display: flex;
						align-items: center;
						justify-content: center;
						.xyds-item-content-item {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 54px;
							height: 70px;
							margin: 0 4px;
							background-size: 100%;
							background-repeat: no-repeat;
							background-image: url(../../assets/images/xyds/number_bj.png);
							span {
								font-size: 66px;
								font-family: 'bigdsfont';
								font-weight: 400;
								color: #ffffff;
							}
						}
					}
				}
				&:nth-child(2) {
					top: 260px;
					.xyds-item-content {
						height: 244px;
						overflow: hidden !important;
					}
				}
				&:nth-child(3) {
					top: 554px;
					.xyds-item-content {
						height: 260px;
						overflow: hidden !important;
					}
				}
				&:nth-child(4) {
					top: 868px;
					.xyds-item-content {
						height: 160px;
						.xyds-item-content-title {
							display: flex;
							align-items: center;
							justify-content: center;
							height: 40px;
							margin-top: 15px;
							background-size: 100%;
							background-repeat: no-repeat;
							background-image: url(../../assets/images/xyds/operate_title.png);
							span {
								font-size: 18px;
								font-family: PingFang SC;
								font-weight: bold;
								color: #ffffff;
								padding: 0 20px;
							}
						}
						.xyds-item-content-item-wrap {
							display: flex;
							flex-direction: column;
							.xyds-item-content-item {
								display: flex;
								align-items: center;
								justify-content: center;
								padding: 8px 0;
								border-bottom: solid transparent 1px;
								border-image: linear-gradient(to right, transparent, #1a99ff, rgb(88, 251, 251), #1a99ff, transparent) 1 0;
								span {
									text-align: center;
									width: 25%;
								}
							}
						}
					}
				}
			}
		}
		.xyds-right-wrap {
			.xyds-item {
				&:nth-child(1) {
					top: 105px;
					.xyds-item-content {
						height: 266px;
						overflow: hidden !important;
					}
				}
				&:nth-child(2) {
					top: 423px;
					.xyds-item-content {
						height: 265px;
						overflow: hidden !important;
					}
				}
				&:nth-child(3) {
					top: 743px;
					.xyds-item-content {
						height: 283px;
						.xyds-item-content-title {
							display: flex;
							align-items: center;
							justify-content: center;
							height: 40px;
							margin-top: 15px;
							background-size: 100%;
							background-repeat: no-repeat;
							background-image: url(../../assets/images/xyds/operate_title.png);
							span {
								font-size: 18px;
								font-family: PingFang SC;
								font-weight: bold;
								color: #ffffff;
								padding: 0 30px;
							}
						}
						.xyds-item-content-item-wrap {
							display: flex;
							flex-direction: column;
							.xyds-item-content-item {
								display: flex;
								align-items: center;
								justify-content: center;
								padding: 8px 0;
								border-bottom: solid transparent 1px;
								border-image: linear-gradient(to right, transparent, #1a99ff, rgb(88, 251, 251), #1a99ff, transparent) 1 0;
								span {
									text-align: center;
									width: 25%;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
