<!--
 * @Author: ch3nh2
 * @Date: 2023-08-28 17:40:21
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-09-01 10:08:15
 * @FilePath: \beilunchanye_fe_20211208\src\components\xyds\Overview.vue
 * @Description: 馨雅大厦 - 园区总览
-->

<template>
	<div class="xyds-components xyds-overview">
		<div v-show="$store.state.animateDelay && $store.state.mapType" class="xyds-wrap xyds-left-wrap" :class="{ animate__animated: true, animate__fadeInLeftBig: $store.state.showDataValue, animate__fadeOutLeftBig: !$store.state.showDataValue }">
			<!-- 园区概况 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">园区概况</div>
				</div>
				<div class="xyds-item-content">
					<div class="xyds-item-content-wrap">
						<div class="xyds-item-content-item-top">
							<div class="xyds-item-content-item-chart">
								<div id="Chart1" :style="{ width: '120px', height: '120px' }"></div>
								<div class="xyds-item-content-item-chart-circle">
									<div class="xyds-item-content-item-chart-circle-data">
										<CountUp :delay="1000" :endVal="86.98" :options="{ decimalPlaces: 2 }" />
										<b>(%)</b>
									</div>
									<p>出租率</p>
								</div>
							</div>
							<div class="xyds-item-content-item-top-data">
								<div class="xyds-item-content-item-top-data-item" v-for="(item, index) in acreage" :key="index">
									<CountUp :delay="1000" :endVal="item.num" />
									<p>{{ item.name }}</p>
								</div>
							</div>
						</div>
						<div class="xyds-item-content-item-bottom">
							<div class="xyds-item-content-item-bottom-item" v-for="(item, index) in overview" :key="index">
								<CountUp :delay="1000" :endVal="item.num" />
								<p>{{ item.name }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 车位概况 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">车位概况</div>
				</div>
				<div class="xyds-item-content">
					<div class="xyds-item-content-wrap">
						<div class="xyds-item-content-item">
							<div class="xyds-item-content-item-chart-data">
								<div>
									<CountUp :delay="1000" :endVal="10" />
									<b>(%)</b>
								</div>
								<p>充电桩停车位</p>
							</div>
						</div>
						<div class="xyds-item-content-item">
							<div class="xyds-item-content-item-chart">
								<div class="xyds-item-content-item-chart-data">
									<div>
										<CountUp :delay="1000" :endVal="64" />
										<b>(个)</b>
									</div>
									<p>地上停车位</p>
								</div>
							</div>
							<div class="xyds-item-content-item-data">
								<div class="xyds-item-content-item-data-item" v-for="(item, index) in parkingSpace" :key="index">
									<p>{{ item.name }}：</p>
									<CountUp :delay="1000" :endVal="item.num" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 园区位置 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">园区位置</div>
				</div>
				<div class="xyds-item-content">
					<div class="xyds-item-map" />
				</div>
			</div>
		</div>
		<div v-show="$store.state.animateDelay && $store.state.mapType" class="xyds-wrap xyds-right-wrap" :class="{ animate__animated: true, animate__fadeInRightBig: $store.state.showDataValue, animate__fadeOutRightBig: !$store.state.showDataValue }">
			<!-- 入驻企业 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">入驻企业</div>
				</div>
				<div class="xyds-item-content">
					<div class="xyds-item-content-wrap">
						<div class="xyds-item-content-item" v-for="(item, index) in company">
							<div class="xyds-item-content-item-logo" :style="{ border: index % 2 ? '2px solid #46ffff' : '2px solid #1a96ff' }">
								<img :src="item.logo" :alt="item.name" />
							</div>
							<div class="xyds-item-content-item-name">{{ item.name }}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 人车数量统计 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">人车数量统计</div>
				</div>
				<div class="xyds-item-content">
					<div class="xyds-item-content-wrap">
						<div class="xyds-item-content-item-top">
							<div class="xyds-item-content-item-top-chart">
								<CountUp :delay="1000" :endVal="657" />
								<p>总人数</p>
							</div>
							<div class="xyds-item-content-item-top-chart">
								<CountUp :delay="1000" :endVal="187" />
								<p>车辆总数</p>
							</div>
						</div>
						<div class="xyds-item-content-item-bottom">
							<div class="xyds-item-content-item-bottom-item" v-for="(item, index) in totalList" :key="index">
								<div class="xyds-item-content-item-bottom-item-icon"></div>
								<div class="xyds-item-content-item-bottom-item-data">
									<CountUp :delay="1000" :endVal="item.num" />
									<p>{{ item.name }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 基础配套服务 -->
			<div class="xyds-item">
				<div class="xyds-item-title-img">
					<div class="xyds-item-title">基础配套服务</div>
				</div>
				<div class="xyds-item-content">
					<div class="xyds-item-content-swiper">
						<swiper class="swiper" :options="swiperOption">
							<swiper-slide v-for="(item, index) in service" :key="index">
								<div class="xyds-item-content-swiper-item-wrap">
									<div
										v-for="(jtem, jndex) in item.list"
										:class="{
											'xyds-item-content-swiper-item': true,
											'xyds-item-content-swiper-item-active': serviceActive == jndex,
										}"
										:key="jndex"
										@click="serviceActive = jndex"
									>
										<span>{{ jtem }}</span>
									</div>
								</div>
							</swiper-slide>
							<div class="swiper-button swiper-button-prev" slot="button-prev"></div>
							<div class="swiper-button swiper-button-next" slot="button-next"></div>
						</swiper>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CountUp from 'vue-countup-v2';
	import { returnAllPageFunc } from '@/utils/utils';
	import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
	import 'swiper/css/swiper.css';
	export default {
		data() {
			return {
				acreage: [
					{
						num: 20044,
						name: '建筑面积(㎡)',
					},
					{
						num: 16048.41,
						name: '可租赁面积(㎡)',
					},
				],
				overview: [
					{
						num: 13958.38,
						name: '已出租面积(㎡)',
					},
					{
						num: 2090.03,
						name: '未出租面积(㎡)',
					},
				],
				parkingSpace: [
					{
						name: '商业',
						num: 21,
					},
					{
						name: '办公',
						num: 38,
					},
					{
						name: 'VIP',
						num: 5,
					},
				],
				company: [
					{
						logo: require('@/assets/images/xyds/hxnk_logo.jpg'),
						name: '北京海新能源科技股份有限公司',
					},
					{
						logo: require('@/assets/images/xyds/zj_logo.png'),
						name: '北京中关村中技知识产权服务集团有限公司',
					},
					{
						logo: require('@/assets/images/xyds/hdfz_logo.jpg'),
						name: '北京海淀科技发展有限公司',
					},
					{
						logo: require('@/assets/images/xyds/hxy_logo.jpg'),
						name: '北京海新域城市更新建设发展有限公司',
					},
				],
				totalList: [
					{
						name: '员工',
						num: 641,
					},
					{
						name: '访客',
						num: 16,
					},
					{
						name: '临停',
						num: 5,
					},
					{
						name: '月保',
						num: 182,
					},
				],
				service: [
					{
						list: ['多功能报告厅', '员工餐厅', '人才公寓', '体育场地'],
					},
				],
				serviceActive: 0,
				swiperOption: {
					autoplay: true,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				},
			};
		},
		watch: {},
		computed: {},
		components: {
			CountUp,
			Swiper,
			SwiperSlide,
		},
		methods: {
			drawChart1() {
				const chart = this.$echarts.init(document.getElementById('Chart1'));
				chart.setOption({
					color: ['#1B99F1', '#0f3957'],
					series: [
						{
							type: 'pie',
							radius: ['85%', '100%'],
							label: {
								show: false,
							},
							data: [45, 55],
						},
					],
				});
			},
		},
		mounted() {
			this.drawChart1();
		},
	};
</script>

<style lang="less">
	.xyds-overview {
		.xyds-left-wrap {
			.xyds-item {
				&:nth-child(1) {
					top: 105px;
					.xyds-item-content {
						height: 295px;
						.xyds-item-content-wrap {
							padding: 30px;
							.xyds-item-content-item-top {
								display: flex;
								align-items: center;
								justify-content: space-between;
								margin: 0 20px;
								.xyds-item-content-item-chart {
									position: relative;
									.xyds-item-content-item-chart-circle {
										top: 14px;
										left: 14px;
										position: absolute;
										display: flex;
										align-items: center;
										justify-content: center;
										flex-direction: column;
										width: 90px;
										height: 90px;
										border: 1px solid #c6eafd;
										border-radius: 50%;
										.xyds-item-content-item-chart-circle-data {
											span {
												font-size: 26px;
												font-family: 'bigdsfont';
												font-weight: bold;
											}
											b {
												font-size: 14px;
												font-family: PingFangSCRegular;
												font-weight: 400;
												color: #ffffff;
												opacity: 0.8;
											}
											p {
												font-size: 16px;
												font-family: PingFangSCRegular;
												font-weight: 400;
												color: #ffffff;
												opacity: 0.8;
											}
										}
									}
								}

								.xyds-item-content-item-top-data {
									.xyds-item-content-item-top-data-item {
										display: flex;
										align-items: center;
										justify-content: center;
										flex-direction: column;
										span {
											font-size: 36px;
											font-family: 'bigdsfont';
											font-weight: bold;
											&:nth-child(1) {
												color: #1a96ff;
											}
											&:nth-child(2) {
												color: #46ffff;
											}
										}
										p {
											font-size: 14px;
											font-family: PingFangSCRegular;
											font-weight: 400;
											color: #ffffff;
											opacity: 0.8;
										}
									}
								}
							}
							.xyds-item-content-item-bottom {
								display: flex;
								align-items: center;
								justify-content: space-between;
								margin-top: 38px;
								.xyds-item-content-item-bottom-item {
									display: flex;
									align-items: center;
									justify-content: center;
									flex-direction: column;
									width: 150px;
									height: 74px;
									background-size: 100%;
									background-repeat: no-repeat;
									span {
										font-size: 36px;
										font-family: 'bigdsfont';
										font-weight: bold;
									}
									p {
										font-size: 14px;
										font-family: PingFangSCRegular;
										font-weight: 400;
										color: #ffffff;
										opacity: 0.8;
									}
									&:nth-child(1) {
										background-image: url(../../assets/images/xyds/overview_item_01_01.png);
										span {
											color: #1a99ff;
										}
									}
									&:nth-child(2) {
										background-image: url(../../assets/images/xyds/overview_item_01_02.png);
										span {
											color: #1ee4ff;
										}
									}
								}
							}
						}
					}
				}
				&:nth-child(2) {
					top: 472px;
					.xyds-item-content {
						height: 200px;
						.xyds-item-content-wrap {
							padding: 34px 14px;
							display: flex;
							align-items: center;
							justify-content: space-between;
							.xyds-item-content-item {
								display: flex;
								align-items: center;
								justify-content: center;
								&:nth-child(1) {
									width: 128px;
									height: 128px;
									border: 2px solid #c6eafd;
									border-radius: 50%;
								}
								&:nth-child(2) {
									display: flex;
									align-items: center;
									justify-content: center;
									.xyds-item-content-item-chart {
										width: 128px;
										height: 128px;
										margin-right: 10px;
										border: 2px solid #c6eafd;
										border-radius: 50%;
										display: flex;
										align-items: center;
										justify-content: center;
									}
									.xyds-item-content-item-data {
										display: flex;
										flex-direction: column;
										width: 100px;
										.xyds-item-content-item-data-item {
											display: flex;
											align-items: center;
											&::before {
												content: '';
												display: inline-block;
												width: 8px;
												height: 8px;
												margin-right: 10px;
											}
											&:nth-child(1) {
												span {
													color: #1a96ff;
												}
												&::before {
													background: #1a96ff;
												}
											}
											&:nth-child(2) {
												span {
													color: #46ffff;
												}
												&::before {
													background: #46ffff;
												}
											}
											&:nth-child(3) {
												span {
													color: #52fac7;
												}
												&::before {
													background: #52fac7;
												}
											}
											span {
												font-size: 30px;
												font-family: 'bigdsfont';
												font-weight: bold;
											}
											p {
												font-size: 14px;
												font-family: PingFangSCRegular;
												font-weight: 400;
												color: #ffffff;
												opacity: 0.8;
											}
										}
									}
								}
								.xyds-item-content-item-chart-data {
									display: flex;
									align-items: center;
									flex-direction: column;
									span {
										font-size: 36px;
										font-family: 'bigdsfont';
										font-weight: bold;
									}
									b {
										font-size: 14px;
										font-family: PingFangSCRegular;
										font-weight: 400;
										color: #ffffff;
										opacity: 0.8;
									}
									p {
										font-size: 16px;
										font-family: PingFangSCRegular;
										font-weight: 400;
										color: #ffffff;
										opacity: 0.8;
									}
								}
							}
						}
					}
				}
				&:nth-child(3) {
					top: 750px;
					.xyds-item-content {
						height: 275px;
						display: flex;
						align-items: center;
						.xyds-item-map {
							width: 100%;
							height: 236px;
							background-size: 100%;
							background-repeat: no-repeat;
							background-image: url(../../assets/images/xyds/map.jpg);
						}
					}
				}
			}
		}
		.xyds-right-wrap {
			.xyds-item {
				&:nth-child(1) {
					top: 105px;
					.xyds-item-content {
						height: 305px;
						.xyds-item-content-wrap {
							display: flex;
							align-items: center;
							justify-content: space-between;
							flex-wrap: wrap;
							padding-bottom: 22px;
							.xyds-item-content-item {
								width: 50%;
								display: flex;
								align-items: center;
								flex-direction: column;
								padding: 18px 0;
								padding-bottom: 0;
								.xyds-item-content-item-logo {
									width: 75px;
									height: 75px;
									border-radius: 24px;
									margin-bottom: 8px;
									display: flex;
									align-items: center;
									justify-content: center;
									img {
										width: 80%;
										max-height: 100%;
										border-radius: 10px;
									}
								}
								.xyds-item-content-item-name {
									color: #ffffff;
									font-size: 16px;
									font-weight: normal;
									font-family: Adobe Heiti Std;
									text-align: center;
									min-height: 3em;
								}
							}
						}
					}
				}
				&:nth-child(2) {
					top: 465px;
					.xyds-item-content {
						height: 275px;
						.xyds-item-content-wrap {
							display: flex;
							justify-content: center;
							flex-direction: column;
							.xyds-item-content-item-top {
								padding: 25px 34px;
								display: flex;
								align-items: center;
								justify-content: space-between;
								.xyds-item-content-item-top-chart {
									width: 148px;
									height: 148px;
									border-radius: 50%;
									display: flex;
									align-items: center;
									justify-content: center;
									flex-direction: column;
									span {
										font-size: 36px;
										font-family: 'bigdsfont';
										font-weight: bold;
										color: #fefefe;
									}
									p {
										margin-top: 4px;
										font-size: 16px;
										font-family: PingFangSCRegular;
										font-weight: 400;
										color: #ffffff;
										opacity: 0.8;
									}
									&:nth-child(1) {
										border: 4px solid rgba(27, 268, 241, 0.2);
									}
									&:nth-child(2) {
										border: 8px solid rgba(27, 268, 241, 0.08);
									}
								}
							}
							.xyds-item-content-item-bottom {
								display: flex;
								align-items: center;
								.xyds-item-content-item-bottom-item {
									width: 25%;
									display: flex;
									justify-content: center;
									&:nth-child(1) {
										.xyds-item-content-item-bottom-item-icon {
											&::before,
											&::after {
												background: #dff0ff;
											}
										}
									}
									&:nth-child(2) {
										.xyds-item-content-item-bottom-item-icon {
											&::before,
											&::after {
												background: #00e8ff;
											}
										}
									}
									&:nth-child(3) {
										.xyds-item-content-item-bottom-item-icon {
											&::before,
											&::after {
												background: #dff0ff;
											}
										}
									}
									&:nth-child(4) {
										.xyds-item-content-item-bottom-item-icon {
											&::before,
											&::after {
												background: #1cf1ff;
											}
										}
									}
									.xyds-item-content-item-bottom-item-icon {
										display: flex;
										position: relative;
										margin-right: 10px;
										&::before {
											content: '';
											display: inline-block;
											width: 8px;
											height: 8px;
										}
										&::after {
											content: '';
											position: absolute;
											left: 50%;
											transform: translateX(-50%);
											display: inline-block;
											width: 1px;
											height: 100%;
										}
									}
									.xyds-item-content-item-bottom-item-data {
										display: flex;
										align-items: center;
										justify-content: center;
										flex-direction: column;
										width: 50px;
									}
									span {
										font-size: 30px;
										font-family: 'bigdsfont';
										font-weight: bold;
										color: #dff0ff;
									}
									p {
										font-size: 14px;
										font-family: PingFangSCRegular;
										font-weight: 400;
										color: #ffffff;
										opacity: 0.8;
									}
								}
							}
						}
					}
				}
				&:nth-child(3) {
					top: 795px;
					.xyds-item-content {
						height: 230px;
						.xyds-item-content-swiper {
							width: 100%;
							height: 229px;
							bottom: 0;
							position: absolute;
							.swiper-container {
								height: 100%;
								.swiper-slide {
									display: flex;
									align-items: center;
									justify-content: center;
									.xyds-item-content-swiper-item-wrap {
										width: 320px;
										display: flex;
										align-items: center;
										justify-content: center;
										flex-direction: column;
										.xyds-item-content-swiper-item {
											display: flex;
											align-items: center;
											justify-content: center;
											width: 100%;
											height: 34px;
											margin-top: 10px;
											background-size: 100%;
											background-repeat: no-repeat;
											background-color: rgba(11, 54, 131, 0.33);
											cursor: pointer;
											span {
												padding: 0 40px;
												font-size: 16px;
												font-weight: 400;
												color: #ffffff;
												font-family: PingFangSCRegular;
											}
										}
										.xyds-item-content-swiper-item-active {
											background-image: url(../../assets/images/xyds/overview_item_06_border.png);
											span {
												font-size: 18px;
												font-weight: bold;
												color: #ffffff;
												font-family: PingFang SC;
												text-shadow: 0px 0px 6px #21a2fc;
											}
										}
									}
								}
							}
							.swiper-button {
								z-index: 999;
								position: absolute;
								width: 38px;
								height: 132px;
								background-size: 100%;
								transform: translateY(-50%);
								margin-top: 0;
								background-repeat: no-repeat;
								&::after {
									display: none;
								}
							}
							.swiper-button-prev {
								left: 8px;
								background-image: url(../../assets/images/xyds/prev_icon.png);
							}
							.swiper-button-next {
								right: 8px;
								background-image: url(../../assets/images/xyds/next_icon.png);
							}
						}
					}
				}
			}
		}
	}
</style>
